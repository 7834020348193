import countries from '@/list/countries'
import { withdrawalFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Requested at',
    type: 'datetime',
    filter: {
      startKey: 'startedAt',
      endKey: 'endedAt',
      type: 'datetime',
    },
  },
  {
    key: 'user.username',
    label: 'User',
    type: 'profile',
    image: 'user.avatar',
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.userID}`
      },
    },
  },
  {
    key: 'user.email',
    type: 'email',
    label: 'Email',
    visibility: ['view'],
  },
  {
    key: 'countryCode',
    label: 'Country',
    type: 'country',
    visibility: ['view'],
    filter: {
      key: 'countries',
      type: 'multiple',
      options: countries,
    },
  },
  {
    key: 'user.country',
    label: 'User Country',
    type: 'country',
    visibility: ['view'],
    filter: {
      key: 'userCountries',
      type: 'multiple',
      options: countries,
    },
  },
  {
    key: 'tierSnapshot',
    label: 'Tier',
    type: 'badge',
    map: {
      BOOST_PLUS: 'success',
      BOOST: 'warning',
      FREE: 'secondary',
    },
    filter: {
      key: 'tiers',
      type: 'multiple',
      options: [
        { value: 'FREE', text: 'Free' },
        { value: 'BOOST', text: 'Boost' },
        { value: 'BOOST_PLUS', text: 'Boost Plus' },
      ],
    },
  },
  {
    key: 'data.accountName',
    label: 'Acc. name',
  },
  {
    key: 'data.email',
    label: 'Email/Acc. number',
  },
  {
    key: 'verificationPictureURL',
    type: 'UploadedIDImage',
    label: 'Uploaded ID',
  },
  {
    key: 'verificationPictureUploadedAt',
    label: 'Uploaded at',
    type: 'datetime',
  },
  {
    key: 'verified',
    label: 'Verified ID',
    type: 'verified',
    sortable: false,
  },
  {
    key: 'isVerifiedAt',
    label: 'Verified at',
    type: 'datetime',
  },
  {
    key: 'data.accountNumber',
    label: 'Acc. number',
    visibility: ['view'],
  },
  {
    key: 'data.accountNumber2',
    label: 'Branch number',
    visibility: ['view'],
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    visibility: ['view'],
    map: {
      approved: 'success',
      pending: 'warning',
      rejected: 'danger',
    },
  },
  // all value is in usd
  {
    key: 'amount',
    label: 'Amount USD',
    prefix: '$',
    suffix: ' USD',
  },
  {
    key: 'data.snapshots.withdrawalFeeUSD',
    label: 'Withdrawal Fee USD',
    prefix: '$',
    suffix: ' USD',
  },
  {
    key: 'data.snapshots.amountNet',
    label: 'Net Amount USD',
    prefix: '$',
    suffix: ' USD',
  },
  {
    key: 'data.snapshots.currencyRate',
    label: 'Rate',
  },
  {
    key: 'data.snapshots.convertedAmount',
    label: 'Converted Amount',
  },
  {
    key: 'data.snapshots.conversionFee',
    label: 'Conversion Fee',
  },
  {
    key: 'data.snapshots.withdrawalFee',
    label: 'Withdrawal Fee',
  },
  {
    key: 'data.snapshots.finalAmount',
    label: 'Withdraw Amount',
  },
  {
    key: 'data.snapshots.currency',
    label: 'Currency',
  },
  withdrawalFilterPayment,
  {
    key: 'data.snapshots.bankName',
    label: 'Other Bank',
  },
  {
    key: 'updatedAt',
    label: 'Paid at',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'reason',
    label: 'Reason',
    visibility: ['view'],
  },
  {
    key: 'approvedByUser.username',
    type: 'profile',
    label: 'Approved by',
    visibility: ['view'],
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.approvedByUser}`
      },
    },
    filter: {
      key: 'approvedBy',
      title: 'name',
      type: 'profile',
      stateOptions: 'withdrawal.userData',
      actionSearch: 'withdrawal/getUserData',
      loadInitialOptions: true,
      actionDetail: 'user/getUserData',
    },
  },
]
